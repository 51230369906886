<template>
  <div>
    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      label-width="270px"
    >
      <el-form-item label="经营状态：">
        <el-radio-group v-model.number="form.shopStatus">
          <el-radio :label="1">营业</el-radio>
          <el-radio :label="0">休息</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="待付款订单取消时间："
        prop="unpaidOrderExpireTime"
      >
        <div class="content">
          拍下未付款订单 <el-input
            style="width:80px;"
            max="1440"
            min="20"
            v-model.number="form.unpaidOrderExpireTime"
          ></el-input>分钟内未付款，自动取消订单
        </div>
      </el-form-item>
      <el-form-item
        label="发货后自动确认收货时间："
        prop="deliveryAutoConfirmTime"
      >
        <div class="content">
          发货后 <el-input
            style="width:80px;"
            max="30"
            min="7"
            v-model.number="form.deliveryAutoConfirmTime"
          ></el-input>天，自动确认收货
        </div>
      </el-form-item>
      <el-form-item
        label="买家退货后商家自动确认收货时间："
        prop="refundAutoConfirmReceiveTime"
      >
        <div class="content">
          买家发货后 <el-input
            style="width:80px;"
            max="15"
            min="7"
            v-model.number="form.refundAutoConfirmReceiveTime"
          ></el-input>天，自动确认收货
        </div>
      </el-form-item>
      <el-form-item label="申请退款时间：">
        <div class="content">
          订单完成 <el-input
            style="width:80px;"
            disabled
            v-model.number="form.refundTime"
          ></el-input>天之内可以申请退款
        </div>
      </el-form-item>
    </el-form>
    <div class="btn-wrap">
      <el-button
        type="primary"
        @click="handleSave"
      >保存</el-button>
    </div>
  </div>
</template>

<script>
import { settingCommon, settingCommonUpdate } from '@/api/marketing/shop/set'
export default {
  data () {
    return {
      rules: {
        unpaidOrderExpireTime: [
          { max: 1440, min: 20, required: true, type: 'number', message: '最小20，最大1440', trigger: 'blur' }
        ],
        refundAutoConfirmReceiveTime: [
          { max: 15, min: 7, required: true, type: 'number', message: '最小7，最大15', trigger: 'blur' }
        ],
        deliveryAutoConfirmTime: [
          { max: 30, min: 7, required: true, type: 'number', message: '最小7，最大30', trigger: 'blur' }
        ]
      },
      form: {
        shopStatus: 0,
        unpaidOrderExpireTime: 0,
        deliveryAutoConfirmTime: 0,
        refundTime: 0,
        refundAutoConfirmReceiveTime: 0

      }
    }
  },
  methods: {
    init () {
      settingCommon().then((res) => {
        this.form = res.data
      })
    },
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        settingCommonUpdate(this.form).then((res) => {
          if (res.code === 0) {
            this.$message({ message: '保存成功', type: 'success' })
          }
        })
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
}
</style>
